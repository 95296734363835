* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -o-font-smoothing: antialiased;
}

.w-slider-dot {
  border-radius: 0 !important;
  background-color: var(--base-color-brand--nieczapla-black) !important;
  opacity: 0.5;
}

.w-slider-dot.w-active {
  opacity: 1;
}

@keyframes marquee-scroll {
  from { transform: translateX(0); }
  to { transform: translateX(calc(-100% - 1rem)); }
}

.product_overlay.is-hover-content,
.product_item-overlay {
  transition: all 0.33s ease-out;
}

.product_item:hover .product_overlay.is-hover-content,
.product_item:hover .product_item-overlay {
  top: 0;
}